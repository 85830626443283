import React from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

import SEO from '../components/seo'

const errorAnim = {
  default: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      delayChildren: 0.25,
    },
  },
}

const variants = {
  default: {
    y: '1rem',
    opacity: 0,
  },
  show: {
    y: '0rem',
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.25, 1, 0.5, 1],
    },
  },
}

const NotFoundPage = () => {
  return (
    <>
      <SEO title="Page Not Found" htmlClass="is-404" />
      <motion.section
        className="content content-404"
        initial="default"
        animate="show"
        variants={errorAnim}
      >
        <div className="wrapper">
          <motion.h1 variants={variants}>404</motion.h1>
          <motion.p className="is-uc" variants={variants}>
            Page not found.
          </motion.p>
          <motion.div variants={variants}>
            <Link to="/" className="btn">
              Go Home
            </Link>
          </motion.div>
        </div>
      </motion.section>
    </>
  )
}

export default NotFoundPage
